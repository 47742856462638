
import { IonApp, IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, menuController, alertController } from '@ionic/vue';
import { defineComponent, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { powerOutline, powerSharp } from 'ionicons/icons';
import usefdxAuth from './hooks/fdxAuth'
import fAPI from './integration/foodexAPI'
import { AxiosResponse } from 'axios';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonMenu, 
    IonMenuToggle, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  setup() {
    const appPages = [
      {
        title: 'Dashboard',
        url: '/dashboard',
      },
      {
        title: 'Orders',
        url: '/orders',
      },
      {
        title: 'Restaurants',
        url: '/restaurants',
      },
    ];
    
    const router = useRouter()
    const route = useRoute()
    
    const auth = usefdxAuth();
    
    const toPage = (url: string) => {
      menuController.close()
      router.push(url)
    }

    const doLogout = async () => {
      fAPI.logout()
      .then((response: AxiosResponse)=>{
        console.log('notify server side to log out', response)
        // log out logic
        return auth.logout()
      })
      .then(() => {
        menuController.close()
        router.replace('/login')
      })
    }

    const something = 'BLABLA'

    const presentAlertConfirm = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Warning!',
          message: 'Are you sure you want to sign out?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'YES, Sign me out',
              role: 'confirm',
              handler: () => {
                console.log(something)
                doLogout()
              },
            },
          ],
        });
      return alert.present();
    }

    return {
      appPages,
      powerOutline,
      powerSharp,
      route,
      user: auth.user,
      isAuthenticated: auth.authenticated,
      toPage,
      testExpiry: auth.setATExpired,
      doLogout,
      presentAlertConfirm,
    }
  }
});
