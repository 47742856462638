import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Tailwind Styles */
import './index.css'

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons'
// import { fal } from '@fortawesome/pro-light-svg-icons';
import { faTimes, faUtensilsAlt } from '@fortawesome/pro-regular-svg-icons';
// import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

library.add(faTimes, faCircleNotch, faUtensilsAlt)

import usefdxAuth from './hooks/fdxAuth'

const { authCheck } = usefdxAuth();
const app = createApp(App).use(IonicVue).use(VueAxios, axios);

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

app.config.globalProperties.$filters = {
  currency(value: number) {
    return (value >= 0) ? formatter.format(value) : "";
  },
  phonify(phone: string): string
  {
    if (phone)
      return phone.slice(2, 5) + '-' + phone.slice(5, 8) + '-' + phone.slice(8, 12);
    else
      return phone;
  }
}

app.component("font-awesome-icon", FontAwesomeIcon);

authCheck().then(() => {
  app.use(router);
  return router.isReady();
}).then(() => {
  app.mount('#app');
})

console.log('DOMAIN', process.env.VUE_APP_API_DOMAIN)