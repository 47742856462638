import axios from 'axios';
import usefdxAuth from '../hooks/fdxAuth'

// Constants
const JWT_BASE_URL = process.env.VUE_APP_API_DOMAIN + '/DesktopModules/JwtAuth/API/mobile/';
const ORDER_BASE_URL = process.env.VUE_APP_API_DOMAIN + '/API/FoodexAPI/Order/';
const RESTAURANT_BASE_URL = process.env.VUE_APP_API_DOMAIN + '/API/FoodexAPI/Restaurant/';
const USER_BASE_URL = process.env.VUE_APP_API_DOMAIN + '/API/FoodexAPI/User/';
//const DONATION_API_URL = 'https://foodex.menu/API/FoodexAPI/Donation/';

// const USER_AUTHENTICATION = USER_API_URL + 'Authentication';
// const USER_GETCHECKOUTINFO = USER_API_URL + 'GetCheckoutInfo';
// const USER_CHECKACCESS = USER_API_URL + 'CheckAccess';
// const USER_FAVORITE = USER_API_URL + 'Favorite';
// const USER_UNFAVORITE = USER_API_URL + 'Unfavorite';
// const USER_ORDERHISTORY = USER_API_URL + 'OrderHistory';
// const USER_REDEEMCOUPON = USER_API_URL + 'RedeemCoupon';

// const GET_ADDRESS = USER_API_URL + 'GetAddress';
// const ADD_ADDRESS = USER_API_URL + 'AddAddress';
// const DELETE_ADDRESS = USER_API_URL + 'DeleteAddress';

// const GET_PAYMENT = USER_API_URL + 'GetPayment';
// const ADD_PAYMENT = USER_API_URL + 'AddPayment';
// const DELETE_PAYMENT = USER_API_URL + 'DeletePayment';

// const ORDER_SUBMITV2API = ORDER_API_URL + 'Submitv3';
// const ORDER_SUBMITNONPAYMENTV2API = ORDER_API_URL + 'SubmitNonPaymentv3';
// const ORDER_TORECEIPT = ORDER_API_URL + 'ToReceipt';
// const ORDER_LIVEDETAILS = ORDER_API_URL + 'LiveDetails';

// const DONATION_SRF = DONATION_API_URL + 'SRF';

interface SearchOptions {
    orderID: string;
    customerName: string;
    phoneNumber: string;
    orderType: number;
    shopID: number;
    orderStatus: string;
    startPeriod: string;
    endPeriod: string;
    pageIndex: number;
    pageSize: number;
    calTotal: boolean;
}

const API = {
    USER: {
        LOGIN: JWT_BASE_URL + 'login',
        LOGOUT: JWT_BASE_URL + 'logout',
        EXTEND: JWT_BASE_URL + 'extendtoken',
        GETPAYMENT: USER_BASE_URL + 'GetPayment'
    },
    RESTAURANT: {
        GETBYCITY: RESTAURANT_BASE_URL + 'GetByCity',
        GETINFO: RESTAURANT_BASE_URL + 'GetInfo',
        GETTIMESLOTS: RESTAURANT_BASE_URL + 'GetTimeSlots',
        SEARCH: RESTAURANT_BASE_URL + 'Search',
    },
    ORDER: {
        PING: ORDER_BASE_URL + 'Ping',
        SEARCH: ORDER_BASE_URL + 'Search',
        DECLINE: ORDER_BASE_URL + 'Decline',
        REFUND: ORDER_BASE_URL + 'Refund',
        CHARGE: ORDER_BASE_URL + 'Charge',
        ToRECEIPT: ORDER_BASE_URL + 'ToReceipt'
    },
}

const ORDER_TYPE_MAP = (type: string): number => {
    switch(type){
        case 'Delivery': {
            return 0;
        }
        case 'PickUp': {
            return 1;
        }
        case 'DineIn': {
            return 2;
        }
        default: {
            return -1;
        }
    }
}

const ORDER_STATUS_MAP = (status: string): string => {
    switch(status){
        case 'Confirmed': {
            return 'OrderConfirmed';
        }
        case 'Accepted': {
            return 'OrderAccepted';
        }
        case 'Completed': {
            return 'OrderCompleted';
        }
        case 'Declined': {
            return 'OrderDeclined';
        }
        default: {
            return '';
        }
    }
}

export const TIMEZONE_MAPS = {
    'Newfoundland Standard Time': 'America/St_Johns',
    'Atlantic Standard Time': 'America/Halifax',
    'Eastern Standard Time': 'America/Toronto',
    'Central Standard Time': 'America/Winnipeg',
    'Canada Central Standard Time': 'America/Regina',
    'Mountain Standard Time': 'America/Edmonton',
    'Pacific Standard Time': 'America/Vancouver',
}

const auth = usefdxAuth()
// IMPORTANT
// ALL API REQUEST NEEDS TO USE THIS SPECIAL axios client
const jwtClient = axios.create({})
 
jwtClient.interceptors.request
.use(async (config) => {
  config.headers.common["Authorization"] = `Bearer ${await auth.getAT()}`;
  return config;
})

jwtClient.interceptors.response
.use((response) => {
    return response;
},
async (error) => {
    if (error.response.status === 401) {
        const oldAT = await auth.getAT()
        const oldRT = await auth.getRT()
        const payload = { rtoken: oldRT}

        const response = await axios.post(API.USER.EXTEND, payload, {
            headers: {
                'Authorization': 'Bearer ' + oldAT,
            }
        })
        await auth.extend(response.data)
        error.config.headers["Authorization"] = `Bearer ${response.data.accessToken}`;
        return axios(error.config);
    } else {
        return Promise.reject(error);
    }
})

// const axiosGET = (url: string) => {
//     return axios.get(url)
// }

const axiosPOST = (url: string, data?: any) => {
    return axios.post(url, data)
}

export default {
    // For login, this API doesn't require any special authentication header
    // so it is not using jwtClient
    login(username: any, password: any) {
        return axiosPOST(API.USER.LOGIN, {
            u: username,
            p: password
        })
    },
    logout() {
        return jwtClient.get(API.USER.LOGOUT)
    },
    async testJWT() {
        return jwtClient.post(API.USER.GETPAYMENT)
    },
    async extendToken() {
        const oldRT = await auth.getRT()
        return jwtClient.post(API.USER.EXTEND, { rtoken: oldRT})
    },
    getRestaurantByCity(city: any)//: Observable<RestaurantListingInfo[]>
    {
        return jwtClient.get(API.RESTAURANT.GETBYCITY + '?city=' + city)
    },
    getLatestOrders()
    {
        const options = {
            pageIndex: 0,
            pageSize: 20,
            calTotal: false,
            shopID: 0,
            orderType: -1,
        }
        return jwtClient.post(API.ORDER.SEARCH, options)
    },
    getRestaurantOrders(shopID: number)
    {
        const options = {
            pageIndex: 0,
            pageSize: 20,
            calTotal: false,
            shopID,
            orderType: -1,
        }
        return jwtClient.post(API.ORDER.SEARCH, options)
    },
    searchOrders(pageSize: number, pageIndex: number, orderID: string, phoneNumber: string, customerName: string, status: string, type: string)
    {
        const options = {
            pageIndex,
            pageSize,
            orderID,
            phoneNumber,
            customerName,
            calTotal: false,
            shopID: 0,
            orderType: ORDER_TYPE_MAP(type),
            orderStatus: ORDER_STATUS_MAP(status),
        }
        return jwtClient.post(API.ORDER.SEARCH, options)
    },
    searchRestaurants(keyword: string){
        return jwtClient.post(API.RESTAURANT.SEARCH + '?k=' + keyword)
    },
    getOrderReceipt(orderID: any)
    {
        return jwtClient.get(API.ORDER.ToRECEIPT + '?id=' + orderID)
    },
    declineOrder(orderID: string, reason: string)
    {
        const data = {
            orderID,
            reason,
        }
        return jwtClient.post(API.ORDER.DECLINE, data)
    },
    refundOrder(orderID: string, refundAmount: number, msg: string, source: string)
    {
        const data = {
            orderID,
            amount: refundAmount,
            msg,
            source,
        }
        return jwtClient.post(API.ORDER.REFUND, data)
    },
    chargeOrder(orderID: string, extraAmount: number, msg: string)
    {
        const data = {
            orderID,
            amount: extraAmount,
            msg,
            source: 'MERCHANT'
        }
        return jwtClient.post(API.ORDER.CHARGE, data)
    },
}