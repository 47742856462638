import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import usefdxAuth from '../hooks/fdxAuth'

const auth = usefdxAuth();

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import ('../pages/LoginPage.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import ('../pages/DashboardPage.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import ('../pages/OrdersPage.vue')
  },
  {
    path: '/restaurants',
    name: 'restaurants',
    component: () => import ('../pages/RestaurantsPage.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import ('../pages/ProfilePage.vue')
  },
  {
    path: "/:catchAll(.*)",
    name: '404',
    component: () => import ('../pages/404Page.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log('Is auth?', auth.authenticated.value , to.name)
  if (auth.authenticated.value && to.name === 'login')
  {
    console.log('detect user, auto login')
    next({name: 'dashboard', replace: true})
  }
  else if (!auth.authenticated.value && to.name !== 'login' && to.name !== '404')
  {
    console.log('dont see user obj, blocking access')
    next({name: 'login', replace: true})
  }
  else
    next()
})

export default router
