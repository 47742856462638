import { reactive, toRefs } from "vue"
import { Storage } from "@ionic/storage"

interface LoginState {
    user: any; 
    init: boolean;
    authenticated: boolean;
}

const state = reactive<LoginState>({
    user: null,
    init: false,
    authenticated: false,
})

const EMPTY_USER = {
    name: '',
    email: '',
}

const storage = new Storage()
storage.create()

const AUTH = {
    KEYS: {
        ACCESSTOKEN: 'FDX_ACCESSTOKEN',
        ACCESSTOKEN_EXPIRY: 'FDX_ACCESSTOKEN_EXPIRY',
        RENEWTOKEN: 'FDX_RENEWTOKEN',
        RENEWTOKEN_EXPIRY: 'FDX_RENEWTOKEN_EXPIRY',
        DISPLAYNAME: 'FDX_DISPLAYNAME',
        USERID: 'FDX_USERID',
    },
    CONFIG: {
        ACCESSTOKEN_DURATION_MIN: 59, // token last for 60 minutes, but we take one minute off in case of edge cases
        RENEWTOKEN_DURATION_HOUR: 335, //336 = 24 hours * 14 days. But we take one hour off in case of edge cases
    }
}

export default function () {
    const getAT = async (): Promise<any> => {
        return await storage.get(AUTH.KEYS.ACCESSTOKEN)
    }

    const getRT = async (): Promise<any> => {
        return await storage.get(AUTH.KEYS.RENEWTOKEN)
    }

    const getRTTimeStamp = async (): Promise<any> => {
        return await storage.get(AUTH.KEYS.RENEWTOKEN_EXPIRY)
    }

    const setATExpired = () => {
        console.log('changing AT expiry')
        storage.set(AUTH.KEYS.ACCESSTOKEN_EXPIRY, "2021-08-14T13:20:59.430Z")
    }

    const setRTExpired = () => {
        console.log('changing RT expiry')
        storage.set(AUTH.KEYS.RENEWTOKEN_EXPIRY, "2021-08-14T13:20:59.430Z")
    }

    const extend = async (dnnJWTRecord: any) => {
        console.log('extend this', dnnJWTRecord)
        const expiredAT = new Date();
        expiredAT.setMinutes(expiredAT.getMinutes() + AUTH.CONFIG.ACCESSTOKEN_DURATION_MIN)

        storage.set(AUTH.KEYS.ACCESSTOKEN, dnnJWTRecord.accessToken)
        storage.set(AUTH.KEYS.ACCESSTOKEN_EXPIRY, expiredAT.toISOString())
        // WE DO NOT EXTEND THE RENEW TOKEN
        // IT WILL EXPIRE IN 14 DAYS AFTER THE PASSWORD IS ENTERED
        storage.set(AUTH.KEYS.RENEWTOKEN, dnnJWTRecord.renewalToken)
    }

    const login = (dnnJWTRecord: any) => {
        extend(dnnJWTRecord)

        const expiredRT = new Date();
        expiredRT.setHours(expiredRT.getHours() + AUTH.CONFIG.RENEWTOKEN_DURATION_HOUR)
        storage.set(AUTH.KEYS.RENEWTOKEN_EXPIRY, expiredRT.toISOString())

        storage.set(AUTH.KEYS.DISPLAYNAME, dnnJWTRecord.displayName)
        storage.set(AUTH.KEYS.USERID, dnnJWTRecord.userId)
        
        state.user = { name: dnnJWTRecord.displayName }
        state.authenticated = true
    }

    const logout = async () => {
        storage.remove(AUTH.KEYS.ACCESSTOKEN)
        storage.remove(AUTH.KEYS.ACCESSTOKEN_EXPIRY)

        storage.remove(AUTH.KEYS.RENEWTOKEN)
        storage.remove(AUTH.KEYS.RENEWTOKEN_EXPIRY)

        storage.remove(AUTH.KEYS.DISPLAYNAME)
        storage.remove(AUTH.KEYS.USERID)

        state.authenticated = false
        state.user = EMPTY_USER
    }

    // RUN AT STARTUP
    const authCheck = async () => {

        if (!state.init) {
            state.init = true
            const expiredRTTimeStamp = await getRTTimeStamp()
            if (expiredRTTimeStamp)
            {
                const nowISO = (new Date()).toISOString()
                if (expiredRTTimeStamp > nowISO) // if expiry is greater than now, then this token can still be use
                {
                    const displayName = await storage.get(AUTH.KEYS.DISPLAYNAME)
                    state.user = { name: displayName }
                    state.authenticated = true // then we proceed to login user
                    return
                }
            }

            state.user = EMPTY_USER
            state.authenticated = false
        }
    }

    // RUN AT STARTUP
    const fakeAuthCheck = () => {
        console.log('fake auth check')
        if (!state.init) {
            state.init = true
            state.user = { name: 'Test' }
            state.authenticated = true // then we proceed to login user
        }
    }

    return {
        ...toRefs(state),
        login,
        logout,
        extend,
        getAT,
        getRT,
        getRTTimeStamp,
        authCheck,
        fakeAuthCheck,
        setATExpired,
        setRTExpired,
    }
}