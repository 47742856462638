<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay" class="lg:max-w-xs" :class="(route.name === 'login' || route.name === '404') && 'hidden'">
        <ion-content>
          <div v-if="isAuthenticated" class="relative flex-1 flex flex-col w-full h-full bg-white border-r border-gray-200">

            <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div class="flex items-center flex-shrink-0 px-4">
                <img class="h-8 w-auto" src="https://foodex.menu/Portals/_default/Skins/Foodex-v2/icons/foodex-logo.svg" alt="foodex" />
              </div>
              <div class="mt-5 flex-1 px-4">
                <ion-list id="inbox-list" class="w-full">
                  <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
                    <ion-item @click="toPage(p.url)" lines="none" detail="false" class="hydrated cursor-pointer" :class="{ selected: route.path === p.url }">
                      <ion-label>{{ p.title }}</ion-label>
                    </ion-item>
                  </ion-menu-toggle>
                </ion-list>
              </div>
            </div>

            <div class="w-full flex-shrink-0 flex justify-between items-center border-t border-gray-200 p-4">
              <div class="flex-grow flex">
                <div class="h-10 w-10 rounded-full bg-red-400 text-white flex items-center justify-center" @click="testExpiry()">
                  {{ user.name[0] }}
                </div>
                <div class="ml-3 my-auto">
                  <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    {{ user.name }}
                  </p>
                </div>
              </div>
              
              <div class="flex-shrink-0">
                <button type="button" class="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-peach" @click="presentAlertConfirm()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" class="bg-cultured-lighter"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonContent, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonRouterOutlet, IonSplitPane, menuController, alertController } from '@ionic/vue';
import { defineComponent, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { powerOutline, powerSharp } from 'ionicons/icons';
import usefdxAuth from './hooks/fdxAuth'
import fAPI from './integration/foodexAPI'
import { AxiosResponse } from 'axios';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonMenu, 
    IonMenuToggle, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  setup() {
    const appPages = [
      {
        title: 'Dashboard',
        url: '/dashboard',
      },
      {
        title: 'Orders',
        url: '/orders',
      },
      {
        title: 'Restaurants',
        url: '/restaurants',
      },
    ];
    
    const router = useRouter()
    const route = useRoute()
    
    const auth = usefdxAuth();
    
    const toPage = (url: string) => {
      menuController.close()
      router.push(url)
    }

    const doLogout = async () => {
      fAPI.logout()
      .then((response: AxiosResponse)=>{
        console.log('notify server side to log out', response)
        // log out logic
        return auth.logout()
      })
      .then(() => {
        menuController.close()
        router.replace('/login')
      })
    }

    const something = 'BLABLA'

    const presentAlertConfirm = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Warning!',
          message: 'Are you sure you want to sign out?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'YES, Sign me out',
              role: 'confirm',
              handler: () => {
                console.log(something)
                doLogout()
              },
            },
          ],
        });
      return alert.present();
    }

    return {
      appPages,
      powerOutline,
      powerSharp,
      route,
      user: auth.user,
      isAuthenticated: auth.authenticated,
      toPage,
      testExpiry: auth.setATExpired,
      doLogout,
      presentAlertConfirm,
    }
  }
});
</script>

<style scoped>

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>